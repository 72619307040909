import { AdviceAndGuidanceCTA } from "@/components/common/AdviceAndGuidanceCTA/AdviceAndGuidanceCTA";
import { PlaceObituaryCTA } from "@/components/common/PlaceObituaryCTA/PlaceObituaryCTA";
import { theme } from "@/components/contexts";
import styled from "@/components/contexts/ThemeProvider/styled";
import { useMediaQuery } from "@mui/material";
import { InnerContainer, RightSide } from "./SlugPage.styled";

export const SlugPageBottom = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Root>
      <LeftRoot>
        <PlaceObitRoot>
          <PlaceObituaryCTA isVertical placement="standard" />
        </PlaceObitRoot>
      </LeftRoot>
      <RightSide>
        <AdviceAndGuidanceCTA isVertical={isMobile} />
      </RightSide>
    </Root>
  );
};

const Root = styled(InnerContainer)(({ theme }) => ({
  maxWidth: "1200px",
  margin: "0 auto",
  padding: "0 24px 40px",
  alignItems: "center",

  [theme.breakpoints.up("md")]: {
    alignItems: "start",
  },
}));

const LeftRoot = styled("div")(({ theme }) => ({
  width: 360,
  display: "flex",
  justifyContent: "center",

  [theme.breakpoints.up("md")]: {
    justifyContent: "start",
  },
}));

const PlaceObitRoot = styled("div")(() => ({
  width: 300,
}));
