import { Colors } from "@/components/contexts";
import { Typography } from "@gannettdigital/community-hub-components";
import { Box, Stack } from "@mui/material";
import { AdviceArticles } from "./AdviceArticles";

interface AdviceAndGuidanceCTAProps {
  isVertical?: boolean;
}

export const AdviceAndGuidanceCTA = ({
  isVertical = false,
}: AdviceAndGuidanceCTAProps) => {
  return (
    <Box mb={2} className="iAdvice">
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        pb={isVertical ? "16px" : "12px"}
      >
        <Typography variant="h2" fontSize={isVertical ? "24px" : "20px"}>
          Advice and guidance
        </Typography>

        {isVertical && (
          <Box flex="1" width="auto" height="4px" mt="5px" bgcolor={Colors.blue5} />
        )}
      </Stack>

      <AdviceArticles isVertical={isVertical} />
    </Box>
  );
};
