import styled from "@/components/contexts/ThemeProvider/styled";

export const InnerContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    gap: theme.spacing(6),
  },
}));

export const RightSide = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",

  [theme.breakpoints.up("md")]: {
    maxWidth: "660px",
    alignItems: "start",
  },
}));
