import { ImageRender } from "@/components/common/ImageRender";
import { Colors } from "@/components/contexts";
import { INDEX_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";
import {
  Link,
  unifySansBoldFontFamily,
} from "@gannettdigital/community-hub-components";
import { Divider, Stack, Typography } from "@mui/material";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const articleList = [
  {
    src: `${publicRuntimeConfig.cdnPrefix}/presto/2020/10/08/FSTH/8970ecc0-5867-43b6-854f-40d56fc86fc8-6c4075b9-1e84-4edd-8439-54bf0eb74746.jpg?crop=2399,1650,x0,y1440&width=550`,
    srcMobile: `${publicRuntimeConfig.cdnPrefix}/presto/2020/10/08/FSTH/8970ecc0-5867-43b6-854f-40d56fc86fc8-6c4075b9-1e84-4edd-8439-54bf0eb74746.jpg?width=80&height=80&fit=crop`,
    title: "Step-by-step guide to planning a funeral",
    link: "https://www.featherstitch.com/advice/ceremony/step-step-guide-planning-funeral/3445080001/",
    type: "Planning",
  },
  {
    src: `${publicRuntimeConfig.cdnPrefix}/presto/2020/10/07/FSTH/ccf7c748-63aa-4f21-992f-d964e9df19b3-c8a40e82-05e4-4686-8a54-cd286ae46db5.jpg?width=550`,
    srcMobile: `${publicRuntimeConfig.cdnPrefix}/presto/2020/10/07/FSTH/ccf7c748-63aa-4f21-992f-d964e9df19b3-c8a40e82-05e4-4686-8a54-cd286ae46db5.jpg?width=80&height=80&fit=crop`,
    title: "Coping with grief when a traditional funeral isn't possible",
    link: "https://www.featherstitch.com/advice/grief-and-loss/coping-grief-when-traditional-funeral-isnt-possible/5617799002/",
    type: "Grief",
  },
  {
    src: `${publicRuntimeConfig.cdnPrefix}/presto/2020/10/08/FSTH/2b93b587-eac7-4e8c-a589-ffbe3b6d2d88-0421c9ab-39e6-48dd-b725-d6ffaf6c1917.jpg?width=550`,
    srcMobile: `${publicRuntimeConfig.cdnPrefix}/presto/2020/10/08/FSTH/2b93b587-eac7-4e8c-a589-ffbe3b6d2d88-0421c9ab-39e6-48dd-b725-d6ffaf6c1917.jpg?width=80&height=80&fit=crop`,
    title: "How to write an obituary",
    link: "https://www.featherstitch.com/advice/communication/how-write-obituary/5643941002/",
    type: "Obituary",
  },
];

interface AdviceArticlesProps {
  isVertical?: boolean;
}

export const AdviceArticles = ({ isVertical = false }: AdviceArticlesProps) => {
  const { trackEvent } = useAnalytics();

  return (
    <Stack
      direction={isVertical ? "column" : "row"}
      gap={1}
      divider={<Divider orientation="horizontal" flexItem />}
    >
      {articleList.map((article, index) => (
        <Link
          key={article.title}
          target="_blank"
          rel="noopener"
          underline="none"
          color="inherit"
          onClick={() =>
            trackEvent({
              action: `article${index}|advice|featherstitch`,
              label: `${article.link}`,
              pageType: INDEX_PAGE_TYPE,
            })
          }
          href={article.link}
        >
          <Stack
            gap={2}
            justifyContent="flex-start"
            direction={isVertical ? "row" : "column"}
            width={isVertical ? 'auto' : 206}
          >
            <ImageRender
              alt=""
              src={isVertical ? article.srcMobile : article.src}
              width={isVertical ? 72 : 206}
              height={isVertical ? 72 : 141}
            />
            <Stack direction="column" gap={1}>
              <Typography
                textAlign="left"
                fontSize={14}
                fontFamily={unifySansBoldFontFamily}
                lineHeight={1.2}
              >
                {article.title}
              </Typography>
              {isVertical && (
                <Typography
                  variant="body2"
                  textAlign="left"
                  fontFamily={unifySansBoldFontFamily}
                  fontSize="11px"
                  textTransform="uppercase"
                  color={Colors.blue6}
                >
                  {article.type}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Link>
      ))}
    </Stack>
  );
};
