import styled from "@/components/contexts/ThemeProvider/styled";
import { type PlaceObitPlacement, usePlaceObit } from "@/hooks/usePlaceObit";
import {
  Button,
  Typography,
  unifySansBoldFontFamily,
} from "@gannettdigital/community-hub-components";
import { Box, Stack } from "@mui/material";
import NextImage from "next/image";
import Art from "./place-obit-art.svg";
import BgBlobSm from "./place-obit-bg-blob-sm.svg";
import BgBlob from "./place-obit-bg-blob.svg";

interface PlaceObituaryCTAProps {
  isVertical?: boolean;
  placement: PlaceObitPlacement;
}

export const PlaceObituaryCTA = ({
  isVertical = false,
  placement,
}: PlaceObituaryCTAProps) => {
  const {
    trackPlaceObit,
    hasPubInfo,
    trackGoDirectorsPage,
    placeObitUrl,
    directorsUrl,
  } = usePlaceObit(placement);

  if (!hasPubInfo) {
    return null;
  }

  return (
    <Stack
      position="relative"
      justifyContent="stretch"
      direction={isVertical ? "column" : "row"}
      alignItems={isVertical ? "stretch" : "center"}
      gap={isVertical ? 0 : 2}
      width={isVertical ? "100%" : "600px"}
      height={isVertical ? "250px" : "230px"}
      pl={isVertical ? 0 : 3}
    >
      {/* Background blob image */}
      <Box
        aria-hidden="true"
        position="absolute"
        left={0}
        width="100%"
        zIndex={-1}
        top={isVertical ? 0 : 5}
      >
        <NextImage
          alt=""
          style={{ width: "100%", height: "auto" }}
          src={isVertical ? BgBlobSm : BgBlob}
        />
      </Box>

      <Box
        aria-hidden="true"
        alignSelf="center"
        pt={isVertical ? 2 : 3}
        width={isVertical ? "105px" : "143px"}
      >
        <NextImage alt="" style={{ width: "100%", height: "auto" }} src={Art} />
      </Box>

      <Stack direction="column" alignItems="stretch" gap={2}>
        <Stack direction="column" gap={0.5}>
          <Typography
            variant="h3"
            fontFamily={unifySansBoldFontFamily}
            fontSize={isVertical ? 20 : 24}
            textAlign={isVertical ? "center" : "left"}
          >
            Need to place an obituary?
          </Typography>
          <Typography
            variant="body2"
            fontSize={isVertical ? 14 : 16}
            textAlign={isVertical ? "center" : "left"}
          >
            We&apos;re here to help.
          </Typography>
        </Stack>

        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="center"
        >
          <Btn disableElevation href={placeObitUrl} onClick={trackPlaceObit}>
            For loved ones
          </Btn>
          <Btn
            disableElevation
            href={directorsUrl}
            onClick={trackGoDirectorsPage}
          >
            Funeral directors
          </Btn>
        </Stack>
      </Stack>
    </Stack>
  );
};

const Btn = styled(Button)({
  margin: 0,
  flex: 1,
  padding: "4px 0",
});
